@import '../../../styles/basics';
@import '../../../styles/mixins/buttons';

.button {
  font-weight: $font-weight-semi-bold;
  display: inline-block;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border: 1px solid;
  border-radius: 25px;
  cursor: pointer;
  padding: 8px 28px;
  transition: 0.3s all;
  position: relative;
  margin: 0 16px 16px 0;
  text-align: center;

  &:hover,
  &.hover {
    text-decoration: none;
  }

  &:disabled,
  &[aria-disabled="true"],
  &.button--disabled {
    cursor: default;
  }

  &.button-small {
    font-size: 14px;
    line-height: 16px;
    padding: 7px 20px;
  }

  &.button-large {
    font-size: 18px;
    line-height: 30px;
    padding-top: 6px;
  }

  &.button-extra-large {
    font-weight: $font-weight-bold;
    font-size: 16px;
    text-transform: uppercase;
    padding: 14px 38px;
    border-radius: 27px;

    &.button-loading .spinner {
      top: auto;
    }
  }

  &.button-caps {
    font-weight: $font-weight-bold;
    font-size: 16px;
    text-transform: uppercase;
    padding-left: 32px;
    padding-right: 32px;
  }

  &.button-block {
    display: block;
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
  }

  &.button-mobile-block {
    display: block;
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
    @include media-breakpoint-up(md) {
      width: auto;
      display: inline-block;
    }
  }

  &.button-primary {
    background: $pink-800;
    border-color: $pink-800;
    color: $white;

    &.button-outline {
      background: $white;
      color: $pink-800;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      @include button-primary-hover;
    }

    &:active {
      background: $pink-1200;
      border-color: $pink-1200;
      color: $white;
    }

    &:disabled,
    &[aria-disabled="true"],
    &.button--disabled {
      background: $gray-200;
      border-color: $gray-200;
      color: $gray-600;

      &.button-outline {
        background: $gray-200;
        color: $gray-600;
      }
    }
  }

  &.button-secondary {
    background: $turquoise-1000;
    border-color: $turquoise-1000;
    color: $white;

    &.button-outline {
      background: $white;
      color: $turquoise-1000;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      @include button-secondary-hover;
    }

    &:active {
      background: $turquoise-1200;
      border-color: $turquoise-1200;
      color: $white;
    }

    &:disabled,
    &[aria-disabled="true"],
    &.button--disabled {
      background: $gray-200;
      border-color: $gray-200;
      color: $gray-600;

      &.button-outline {
        background: $gray-200;
        color: $gray-600;
      }
    }
  }

  &.button-emergency {
    background: $red-800;
    border-color: $red-800;
    color: $white;

    &.button-outline {
      background: $white;
      color: $red-800;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      background: $red-1000;
      border-color: $red-1000;
      color: $white;

      &.light {
        background: $red-600;
        border-color: $red-600;
      }
    }

    &:active {
      background: $red-1000;
      border-color: $red-1000;
      color: $white;
    }

    &:disabled {
      background: $red-600;
      border-color: $red-600;

      &.button-outline {
        background: $white;
        color: $red-600;
      }
    }
  }

  &.button-xmas {
    background: $red-1000;
    border-color: $red-1000;
    color: $white;

    &.button-outline {
      background: $white;
      color: $red-1000;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      background: $black;
      border-color: $black;
      color: $white;

      &.light {
        background: $black;
        border-color: $black;
      }
    }

    &:active {
      background: $black;
      border-color: $black;
      color: $white;
    }

    &:disabled {
      background: $gray-200;
      border-color: $gray-200;
      color: $gray-600;

      &.button-outline {
        background: $white;
        color: $gray-600;
      }
    }
  }

  &.button-dark {
    background: $slate-1200;
    border-color: $slate-1200;
    color: $white;

    &.button-outline {
      background: transparent;
      color: $slate-1200;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      background: $white;
      border-color: $slate-200;
      color: $slate-1200;

      &.button-outline {
        color: $white;
        background: $slate-1200;
      }
    }

    &:active {
      background: $slate-1200;
      border-color: $slate-1000;
      color: $white;
    }

    &:disabled {
      background: $gray-200;
      border-color: $gray-200;
      color: $gray-600;

      &.button-outline {
        background: transparent;
        color: $gray-600;
      }
    }
  }

  &.button-light {
    background: $white;
    border-color: $white;
    color: $slate-1200;

    &:hover,
    &.hover,
    &:focus-visible {
      background: $slate-1200;
      border-color: $slate-1000;
      color: $white;
    }

    &:active {
      background: $slate-1200;
      border-color: $slate-1000;
      color: $white;
    }

    &:disabled {
      background: $gray-200;
      border-color: $gray-200;
      color: $gray-600;
    }
  }

  &.button-neutral {
    background: $slate-200;
    border-color: $slate-200;
    color: $slate-1200;

    &:hover,
    &.hover,
    &:focus-visible {
      background: $slate-400;
      border-color: $slate-200;
      color: $slate-1200;
    }

    &:active {
      background: $slate-400;
      border-color: $slate-200;
      color: $slate-1200;
    }

    &:disabled {
      background: $gray-200;
      border-color: $gray-200;
      color: $gray-600;
    }
  }

  &.button-cobalt {
    background: $deep-blue-500;
    border-color: $deep-blue-500;
    color: $white;

    &:hover,
    &.hover,
    &:focus-visible {
      background: $deep-blue-500;
      border-color: $deep-blue-500;
      color: $white;
    }

    &:active {
      background: $deep-blue-600;
      border-color: $deep-blue-500;
      color: $white;
    }
  }

  &.button-tangerine {
    background: $yellow-800;
    border-color: $yellow-800;
    color: $black;

    &:hover,
    &.hover,
    &:focus-visible {
      background: $yellow-1000;
      border-color: $yellow-1000;
      color: $black;
    }

    &:active {
      background: $yellow-1000;
      border-color: $yellow-1000;
      color: $black;
    }
  }

  &.button-activism {
    background: $blue-800;
    border-color: $blue-800;
    color: $white;

    &:hover,
    &.hover,
    &:focus-visible {
      background: $blue-1000;
      border-color: $blue-1000;
      color: $white;
    }

    &:active {
      background: $blue-1000;
      border-color: $blue-1000;
      color: $white;
    }
  }

  &.button-activism-yellow {
    background: $yellow-600;
    border-color: $yellow-600;
    color: $black;

    &:hover,
    &.hover,
    &:focus-visible {
      background: $yellow-800;
      border-color: $yellow-800;
      color: $black;
    }

    &:active {
      background: $yellow-800;
      border-color: $yellow-800;
      color: $black;
    }
  }

  &.button-activism-black {
    background: $black;
    border-color: $black;
    color: $white;

    &.button-outline {
      background: transparent;
      color: $black;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      background: $black;
      border-color: $black;
      color: $white;
    }

    &:active {
      background: $black;
      border-color: $black;
      color: $white;
    }
  }

  &.button-activism-white {
    background: $white;
    border-color: $white;
    color: $black;

    &.button-outline {
      background: transparent;
      color: $white;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      background: $white;
      border-color: $white;
      color: $black;
    }

    &:active {
      background: $white;
      border-color: $white;
      color: $black;
    }
  }

  &.button-white-pink {
    background: $white;
    border-color: $white;
    color: $pink-800;

    &.button-outline {
      background: transparent;
      color: $white;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      background: $pink-1000;
      border-color: $pink-1000;
      color: $white;

      &.light {
        background: $pink-600;
        border-color: $pink-600;
      }
    }

    &:active {
      background: $pink-1200;
      border-color: $pink-1200;
      color: $white;
    }

    &:disabled {
      background: $pink-600;
      border-color: $pink-600;
      color: $white;

      &.button-outline {
        background: transparent;
        color: $pink-600;
        border-color: $pink-600;
      }
    }
  }

  &.button-white-green {
    background: $white;
    border-color: $white;
    color: $turquoise-1000;

    &.button-outline {
      background: transparent;
      color: $white;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      background: $turquoise-1200;
      border-color: $turquoise-1200;
      color: $white;

      &.light {
        background: $turquoise-800;
        border-color: $turquoise-800;
      }
    }

    &:active {
      background: $turquoise-1200;
      border-color: $turquoise-1200;
      color: $white;
    }

    &:disabled {
      background: $turquoise-800;
      border-color: $turquoise-800;
      color: $white;

      &.button-outline {
        background: transparent;
        color: $turquoise-800;
      }
    }
  }

  &.button-untoggled,
  &.button-toggled {
    height: 44px;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;
    padding-inline: 2rem;
    border-radius: 4px;
    background-color: $turquoise-1000;
    color: $white;
    border: 0;
    margin: 0;

    &.button-with-arrow {
      svg {
        top: 0;
        transform: rotate(90deg);
        right: -10px;
      }

      &:hover svg,
      &:focus svg,
      &:focus-visible svg {
        right: -10px;
      }
    }
  }

  &.button-toggled.button-with-arrow svg {
    top: -2px;
    transform: rotate(270deg);
  }

  &.button-loading {
    padding-left: 44px;

    :global(.spinner) {
      position: absolute;
      left: 11px;
      top: 7px;
      @include media-breakpoint-up(md) {
        top: 9px;
      }
    }

    &.button-primary.button-outline span {
      border-color: $pink-600 transparent transparent transparent;
    }

    &.button-secondary.button-outline span {
      border-color: $turquoise-800 transparent transparent transparent;
    }

    &.button-emergency.button-outline span {
      border-color: $red-600 transparent transparent transparent;
    }

    &.button-white-pink.button-outline span {
      border-color: $pink-600 transparent transparent transparent;
    }

    &.button-white-green.button-outline span {
      border-color: $turquoise-800 transparent transparent transparent;
    }

    &.button-small {
      padding-left: 34px;

      .spinner {
        top: 7px;
        left: 11px;
        height: 16px;
        width: 16px;

        span {
          height: 16px;
          width: 16px;
          border-width: 2px;
        }
      }
    }
  }

  &.button-with-arrow,
  &.button-with-back-arrow {
    white-space: nowrap;

    svg {
      width: 10px;
      height: 13px;
      position: relative;
      right: -1px;
      top: -1px;
      margin-left: 8px;
      transition: 0.3s right;

      path {
        fill: none !important;
        stroke: currentColor;
      }
    }

    &.button-caps svg,
    &.button-extra-large svg {
      top: -2px;
      height: 15px;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      &:not(:disabled, [aria-disabled="true"], &.button--disabled) {
        @include button-with-arrow-hover;
      }
    }
  }

  &.button-with-back-arrow {
    svg {
      transform: rotate(180deg);
      margin-right: 8px;
      margin-left: 0;
      transition: 0.3s right;
    }

    &:hover,
    &.hover,
    &:focus-visible {
      &:not(:disabled, [aria-disabled="true"]) {
        svg {
          right: 1px!important;
        }
      }
    }
  }

  &.button-external {
    white-space: nowrap;

    svg {
      width: 13px;
      height: 13px;
      position: relative;
      top: -2px;
      margin-left: 8px;
      fill: currentColor;

      path {
        fill: currentColor !important;
      }
    }

    &.button-caps svg,
    &.button-extra-large svg {
      width: 15px;
      height: 15px;
    }
  }
}
