@mixin button-primary-hover {
  background: $pink-1000;
  border-color: $pink-1000;
  color: $white;

  &.light {
    background: $pink-800;
    border-color: $pink-800;
  }
}

@mixin button-secondary-hover {
  background: $turquoise-1200;
  border-color: $turquoise-1200;
  color: $white;

  &.light {
    background: $turquoise-600;
    border-color: $turquoise-600;
  }
}

@mixin button-emergency-hover {
  background: $red-1000;
  border-color: $red-1000;
  color: $white;

  &.light {
    background: $red-600;
    border-color: $red-600;
  }
}

@mixin button-with-arrow-hover {
  svg {
    right: -3px;
  }
}

@mixin text-button-default-hover {
  color: $pink-800;
}

@mixin text-button-light-hover {
  color: $white;
}

@mixin text-button-with-arrow-hover {
  svg {
    right: -4px;
  }
}
