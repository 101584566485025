@import '../../../styles/basics';

.spinner {
  display: inline-block;
  position: relative;
  height: 24px;
  width: 24px;

  span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    height: 24px;
    width: 24px;
    border: 3px solid $white;
    border-radius: 50%;
    border-color: $white transparent transparent transparent;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &.turquoise span {
    border-color: $turquoise-1000 transparent transparent transparent;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
